'use client';

import { Inter } from 'next/font/google';
import React from 'react';

const inter = Inter({ subsets: ['latin'] });

const FontProvider = ({ children }: { children: React.ReactNode }) => {
  return <body className={inter.className}>{children}</body>;
};

export default FontProvider;
